import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage/HomePage'
import Homepage2 from '../HomePage2/HomePage2'
import Homepage3 from '../HomePage3/Homepage3'
import AboutPage from '../AboutPage/AboutPage'
import BlogPage from '../BlogPage/BlogPage'
import BlogPageLeft from '../BlogPageLeft/BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth/BlogPageFullwidth'
import BlogDetails from '../BlogDetails/BlogDetails'
import BlogDetailsFull from '../BlogDetailsFull/BlogDetailsFull'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide/BlogDetailsLeftSiide'
import ContactPage from '../ContactPage/ContactPage'
import ErrorPage from '../ErrorPage/ErrorPage.js'
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage'
import ServicePage from '../ServicePage/ServicePage';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage'
import ProjectPage from '../ProjectPage/ProjectPage';
import ProjectSinglePage from '../ProjectSinglePage/ProjectSinglePage'
import AppointmentPage from '../AppointmentPage/AppointmentPage'
import TermPage from '../TermPage/TermPage';
import TestimonialPage from '../TestimonialPage/TestimonialPage';
import LoginPage from '../LoginPage/LoginPage' 
import SignUpPage from '../SignUpPage/SignUpPage' 
import ForgotPassword from '../ForgotPassword/ForgotPassword' 
import PricingPage from '../PricingPage/PricingPage';
      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/contact' component={ContactPage}/> 
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/about' component={AboutPage} />
            <Route path='/service' component={ServicePage}/>
            <Route path='/service-s2' component={ServicePageS2}/>
            <Route path='/service-single/:id' component={ServiceSinglePage}/>
            <Route path='/project' component={ProjectPage}/>
            <Route path='/project-single/:id' component={ProjectSinglePage}/>
            <Route path='/appointment' component={AppointmentPage}/> 
            <Route path='/terms' component={TermPage}/> 
            <Route path='/testimonial' component={TestimonialPage}/> 
            <Route path='/pricing' component={PricingPage}/> 
            <Route path='/404' component={ErrorPage}/> 
            {/*<Route path='/blog-single/:id' component={BlogDetails}/> */}
            {/*<Route path='/blog-single-left-sidebar/:id' component={BlogDetailsLeftSiide}/> */}
            {/*<Route path='/blog-single-fullwidth/:id' component={BlogDetailsFull}/> */}
            {/*<Route path='/blog' component={BlogPage}/> */}
            {/*<Route path='/blog-left-sidebar' component={BlogPageLeft}/>*/}
            {/*<Route path='/blog-fullwidth' component={BlogPageFullwidth}/>*/}
            <Route path='/team-single/:id' component={TeamSinglePage}/>
            {/*<Route path='/login' component={LoginPage}/>*/}
            {/*<Route path='/register' component={SignUpPage}/>*/}
            {/*<Route path='/forgot-password' component={ForgotPassword}/>*/}
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
