import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/customer.jpg'


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

const testimonial = [
    {
      Des: "I am extremely impressed with the cleaning services provided by TamayoClean. Their team, led by Belky, did an exceptional job in making my office spotless. Their attention to detail is remarkable, and I appreciate the care they took to clean even the hard-to-reach areas. I highly recommend their services to anyone looking for a reliable and professional cleaning company.",
        Title: 'Sarah Smith',
        Sub: "Customer",
    },
    {
        Des: "I recently hired TamayoClean Services for a deep cleaning of my home, and I couldn't be happier with the results. The team, led by Maria, was friendly, efficient, and did a fantastic job in getting my home looking and smelling fresh. I was particularly impressed by their use of eco-friendly cleaning products, which made me feel good about the impact on the environment. I will definitely be using their services again.",
        Title: 'Jack Johnson',
        Sub: "Customer",
    },
    {
        Des: "I have been a long-time client of TamayoClean, and I can confidently say that they are the best in the business. The team, led by Michael, consistently delivers exceptional cleaning services, and they are always willing to go the extra mile to ensure that my home is clean and tidy. I appreciate their professionalism, attention to detail, and willingness to work around my schedule. I highly recommend their services to anyone looking for a trustworthy and reliable cleaning company.",
        Title: 'Emily Davis',
        Sub: "Customer",
    }
]

const Testimonial = () => {
    return (
        <section className="wpo-testimonials-section section-padding">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5">
                        <div className="wpo-section-title">
                            <h2>What Clients Say?</h2>
                            {/*<p>It was popularised in the with the release desktop*/}
                            {/*    publishing software like versions .</p>*/}
                        </div>
                    </div>
                </div>
                <div className="wpo-testimonials-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <div className="wpo-testimonials-img">
                                <img src={ts1} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="wpo-testimonials-text">
                                <div className="quote">
                                    <i className="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                                <div className="wpo-testimonials-slide owl-carousel">
                                    <Slider {...settings}>
                                        {testimonial.map((tesmnl, tsm) => (
                                            <div className="wpo-testimonials-slide-item" key={tsm}>
                                                <p>{tesmnl.Des}</p>
                                                <h5>{tesmnl.Title}</h5>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;