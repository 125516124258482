import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'
import simg7 from '../images/service/1.jpg'
import simg8 from '../images/service/7.jpg'
import simg9 from '../images/service/8.jpg'
import simg10 from '../images/service/9.jpg'
import simg11 from '../images/service/5.jpg'
import simg12 from '../images/service/10.jpg'

import sIcon1 from '../images/icon/carpet.svg'
import sIcon2 from '../images/icon/season.svg'
import sIcon3 from '../images/icon/express-delivery.svg'
import sIcon4 from '../images/icon/cutlery.svg'
import sIcon5 from '../images/icon/shower.svg'
import sIcon6 from '../images/icon/1.svg'
import sIcon7 from '../images/icon/2.svg'
import sIcon8 from '../images/icon/3.svg'
import sIcon9 from '../images/icon/4.svg'
import sIcon10 from '../images/icon/5.svg'
import sIcon11 from '../images/icon/5.svg'
import sIcon12 from '../images/icon/5.svg'


import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'



const Services = [
    {
        Id: '1',
        sImg:simg,
        sIcon:sIcon1,
        sTitle: 'Carpet',
        description:'The process of removing dirt, stains, and allergens from carpets using specialized equipment and cleaning solutions. It is an essential part of maintaining the appearance and hygiene of a carpeted area.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sIcon:sIcon2,
        sTitle: 'Seasonal',
        description:'It helps to maintain the cleanliness and hygiene of a home, reduces the accumulation of dust and allergens, and can improve indoor air quality. It also allows for a fresh start and can help to create a more comfortable and inviting living space.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sIcon:sIcon3,
        sTitle: 'Express',
        description:'A quick and efficient cleaning process designed to tackle surface-level dirt and clutter in a space. It typically involves a targeted approach, focusing on high-traffic areas such as kitchens, bathrooms, and living spaces.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sIcon:sIcon4,
        sTitle: 'Kitchen',
        description:'It involves the process of cleaning and sanitizing all surfaces, appliances, and utensils in a kitchen to ensure that it is hygienic and safe for food preparation.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Bathroom',
        description:'Bathroom cleaning typically involves several tasks, such as scrubbing the toilet, sink, and shower or bathtub, wiping down surfaces, and mopping or sweeping floors',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sIcon:sIcon6,
        sTitle: 'Windows',
        description:'Window cleaning involves the process of cleaning and removing dirt, grime, and other contaminants from glass windows to ensure that they are clear and transparent',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:simg7,
        sIcon:sIcon7,
        sTitle: 'Carpet',
        description:'The process of removing dirt, stains, and allergens from carpets using specialized equipment and cleaning solutions. It is an essential part of maintaining the appearance and hygiene of a carpeted area.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:simg8,
        sIcon:sIcon8,
        sTitle: 'Glass',
        description:'It nvolves removing dirt, dust, smudges, and other types of grime from the surface of glass windows, mirrors, or other glass surfaces. To clean glass effectively, one typically uses a cleaning solution, a squeegee or a clean cloth, and some elbow grease.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '9',
        sImg:simg9,
        sIcon:sIcon9,
        sTitle: 'Table',
        description:'Table cleaning involves removing dust, dirt, and other debris from the surface of a table to maintain its cleanliness and appearance. This can be done by wiping the table with a damp cloth or sponge and a mild cleaning solution',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '10',
        sImg:simg10,
        sIcon:sIcon10,
        sTitle: 'Computer',
        description:'Computer cleaning involves removing dust, dirt, and other debris from the various components of a computer to maintain its performance, longevity, and hygiene. This can be done by wiping the exterior surfaces with a soft, lint-free cloth or microfiber towel, and using a can of compressed air to blow out any dust or debris from the keyboard, vents, and other components.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '11',
        sImg:simg11,
        sIcon:sIcon11,
        sTitle: 'Bathroom',
        description:'Bathroom cleaning typically involves several tasks, such as scrubbing the toilet, sink, and shower or bathtub, wiping down surfaces, and mopping or sweeping floors',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '12',
        sImg:simg12,
        sIcon:sIcon12,
        sTitle: 'Chair',
        description:'Chair cleaning involves removing dirt, dust, and other debris from the surface of a chair to maintain its cleanliness and hygiene. This can be done by vacuuming the chair with an upholstery attachment or wiping it down with a damp cloth or sponge and a mild cleaning solution.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]
export default Services;