import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import FAQ from './faq'
import Footer from '../../components/footer/Footer.js'
import tImg1 from '../../images/terms/terms.jpg'
import tImg2 from '../../images/terms/1.jpg'
import tImg3 from '../../images/terms/2.jpg'

const TermPage = (props) => {


    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Terms & Coditions'} pagesub={'Terms & Coditions'} />
            <section className="wpo-terms-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="wpo-terms-wrap">
                                <div className="wpo-terms-text">
                                    <h2>Terms and Conditions</h2>
                                    
                                    <p>
                                        Welcome to our website! By accessing or using our website, you agree to be bound by the following terms and conditions ("T&C"). Please read these terms carefully before using the website. If you do not agree to these terms, please do not use our website.
                                        <br />
                                      <br />
                                        1. Introduction
                                        <br />
                                        This website is owned and operated by Tamayo Clean Services, Inc. These T&C apply to all visitors, users, and others who access or use the website.
                                        <br />
                                      <br />
                                      2. Acceptance of Terms
                                        <br />
                                        By using our website, you agree to be bound by these T&C. If you do not agree to these terms, you may not access or use the website.
                                        <br />
                                      <br />
                                        3. User Conduct
                                        <br />
                                        You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, or restrict or inhibit anyone else's use of, the website. Prohibited activities include, but are not limited to:
                                        <br />
                                        <ul>
                                          <li>Using the website in any way that violates any applicable federal, state, local, or international law or regulation.</li>
                                          <li>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.</li>
                                          <li>Posting or transmitting any unlawful, threatening, abusive, defamatory, obscene, vulgar, or indecent information or material.</li>
                                          <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the website.</li>
                                        </ul>

                                      <br />

                                      <br />
                                        4. Intellectual Property
                                      <br />
                                        All content on the website, including but not limited to text, graphics, logos, images, and software, is the property of Tamayo Clean Services or its content suppliers and is protected by U.S. and international copyright laws. You may not copy, modify, distribute, or reproduce any content from the website without the express written consent of Tamayo Clean Services.
                                      <br />                                <br />
                                        5. Disclaimer
                                      <br />
                                        THE WEBSITE AND ALL CONTENT, MATERIALS, INFORMATION, PRODUCTS, AND SERVICES PROVIDED THROUGH THE WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. Tamayo Clean Services DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
                                      <br />                                <br />
                                        6. Limitation of Liability
                                      <br />
                                        IN NO EVENT SHALL Tamayo Clean Services BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES.
                                      <br />                                <br />
                                        7. Indemnification
                                      <br />
                                        You agree to indemnify and hold Tamayo Clean Services, its affiliates, officers, directors, employees, and agents harmless from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your use of the website.
                                      <br />                                <br />
                                        8. Termination
                                      <br />
                                        Tamayo Clean Services reserves the right to terminate your access to the website, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account. All provisions of these T&C that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                                      <br />                                <br />
                                        9. Governing Law and Jurisdiction
                                      <br />
                                        These T&C shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law. Any dispute arising out of or relating to these T&C shall be subject to the exclusive jurisdiction of the state and federal courts located in Los Angeles County, California.
                                      <br />                                <br />
                                        10. Changes to Terms and Conditions
                                      <br />
                                        Tamayo Clean Services reserves the right to modify these T&C at any time. You should review these T&C periodically for changes. By using the website after we post any changes to these T&C, you agree to accept those changes, whether or not you have reviewed them.
                                      <br />                                <br />
                                        11. Entire Agreement
                                      <br />
                                        These T&C, together with our Privacy Policy, constitute the entire agreement between you and Tamayo Clean Services regarding your use of the website. Any failure by Tamayo Clean Services to enforce any right or provision in these T&C shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Tamayo Clean Services in writing.
                                      <br />                                <br />
                                        If you have any questions or concerns regarding these T&C, please contact us at info@tamayoclean.com.
                                    </p>

                                    <div className="row t-sub">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src={tImg2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src={tImg3} alt=""/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/*<FAQ />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TermPage;
