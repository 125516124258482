import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'


const Team = [
    {
       Id:'1',
       tImg:timg1,
       name:'Belky Tamayo',
       title:'Cleaner Leader - CEO',
    },
    {
       Id:'2',
       tImg:timg2,
       name:'Ariosbel Peña',
       title:'Cleaner Leader',     
    },
    {
      Id:'3',
      tImg:timg2,
      name:'Nikole Caballero',
      title:'Cleaner Leader',
    },
    {
      Id:'4',
      tImg:timg2,
      name:'Brandon Valle',
      title:'Cleaner Leader',
    },
]

export default Team;