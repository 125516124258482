import React from 'react'
import abimg from '../../images/about.jpg'
import sign from '../../images/signeture.png'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-section-title-s2">
                                    <h2>Over 10+ Years Experience
                                        In Cleaning Service</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                  <p>
                                    An individual with more than 10 years of experience in cleaning and housekeeping services is a seasoned professional with a wealth of knowledge and skills related to the cleaning industry. They have likely worked in a variety of settings, such as residential homes, commercial buildings, hotels, and hospitals, and have developed a deep understanding of the unique cleaning needs and challenges in each of these environments.
                                  </p>
                                  <p>
                                    Having worked in the industry for a significant amount of time, they are well-versed in the best practices and latest trends in cleaning techniques, products, and tools. They understand the importance of using eco-friendly and non-toxic cleaning solutions, as well as the significance of using appropriate cleaning equipment and tools for each specific cleaning task.
                                  </p>
                                  <p>
                                    A professional with over 10 years of experience in cleaning and housekeeping services is also likely to have developed strong communication and customer service skills, as they have interacted with a diverse range of clients and worked closely with them to understand their cleaning needs and preferences.
                                  </p>
                                  <p>
                                    Overall, an individual with more than 10 years of experience in cleaning and housekeeping services brings a level of expertise, professionalism, and attention to detail that can significantly enhance the quality and efficiency of cleaning services in a variety of settings.
                                  </p>

                                    <div className="signeture">
                                        <h4>Belky Tamayo</h4>
                                        <p>TamayoClean - CEO</p>
                                        <span><img src={sign} alt=""/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;